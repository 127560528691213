export default () => ({
    phone: null,
    async init() {

    },
    get btn_style() {
        let classes = {};
        if( this.phone == '' || !(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(this.phone)) )
            classes['disabled'] = true;
        return classes;
    },
    btn_click(link = '') {
        if( link === '' ) return;
        // console.log(link);
        window.open(link);
    }
})