import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
export default () => ({
    id_tab: 1,
    phone: null,
    slider(start = 1000, min = 10, max = 2000, step = 10) {
        noUiSlider.create(this.$refs.slider, {
            start: [start],
            step: step,
            tooltips: [
                { to: function(value) { 
                    document.getElementById('budget_tariff').value = Number(value).toFixed(0);
                    return number_format(Number(value).toFixed(0), 0, '', ' ') + ' ₽'; 
                }}
            ],
            range: {
                'min': [min],
                'max': [max]
            }
        });
    },
    btn_next() {
        if( this.id_tab < 4 ) 
            this.id_tab++;
    },
    btn_prev() {
        if( this.id_tab > 1 )
            this.id_tab--;
    },
    // get btn_rent() {
    //     let classes = {};
    //     if( this.phone == '' || !(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(this.phone)) )
    //         classes['disabled'] = true;
    //     return classes;
    // },
    paginate(id) {
        return this.id_tab === id ? 'active' : 
                id > this.id_tab ? 'next' : 
                id < this.id_tab ? 'prev' : '';
    }
})