// Функция форматирования числового значения
window.number_format = (number, decimals, dec_point, thousands_sep) => {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

window.numDecline = (number, titles, showNumber = true) => {
    if (typeof titles === 'string') {
        titles = titles.split(',').map(title => title.trim());
    }
    // когда указано 2 элемента
    if (titles.length < 3) {
        titles[2] = titles[1];
    }
    const cases = [2, 0, 1, 1, 1, 2];
    const intnum = Math.abs(parseInt(number, 10));
    const titleIndex = (intnum % 100 > 4 && intnum % 100 < 20) ? 2 : cases[Math.min(intnum % 10, 5)];
    return (showNumber ? number + ' ' : '') + titles[titleIndex];
}