// https://codepen.io/JuanFuentes/pen/rgXKGQ
export default () => ({
    init() {
    // Получаем ссылку на заголовок через `this.$refs`.
    const title = this.$refs.name;
    // Переменная для хранения максимального расстояния от курсора до символа.
    var maxDist;
    // Объект для отслеживания текущей позиции мыши.
    var mouse = { x: 0, y: 0 };
    // Объект для отслеживания позиции курсора.
    var cursor = {
        x: window.innerWidth,
        y: window.innerHeight
    };
    // Функция для вычисления расстояния между двумя точками.
    Math.dist = function(a, b) {
        var dx = b.x - a.x;
        var dy = b.y - a.y;
        // Возвращает расстояние между точками.
        return Math.sqrt(Math.pow(dx, 2), Math.pow(dy, 2));
    }
    // Обработчик событий для отслеживания движения мыши.
    this.$root.addEventListener("mousemove", function(e) {
        cursor.x = e.clientX;
        cursor.y = e.clientY;
    });
    // Обработчик событий для отслеживания касаний на сенсорных устройствах.
    this.$root.addEventListener("touchmove", function(e) {
    var t = e.touches[0];
        cursor.x = t.clientX;
        cursor.y = t.clientY;
    }, {
        passive: false
    });
    // Класс `Char` представляет собой отдельный символ и связанные с ним методы.
    var Char = function(container, char) {
        // Создаем элемент span и устанавливаем его содержимое.
        var span = document.createElement("span");
        span.setAttribute('data-char', char);
        span.innerText = char;
        container.appendChild(span);
        // Функция для получения расстояния от символа до мыши.
        this.getDist = function() {
            this.pos = span.getBoundingClientRect();
            return Math.dist(mouse, {
            x: this.pos.x + (this.pos.width / 1.75),
            y: this.pos.y
            });
        }
        // Функция для определения стиля символа на основе удаленности.
        this.getAttr = function(dist, min, max) {
            var wght = max - Math.abs((max * dist / maxDist));
            // Возвращает вес символа, не меньше заданного минимума.
            return Math.max(min, wght + min);
        }
        // Обновление стиля символа на основе его положения относительно мыши.
        this.update = function(args) {
            var dist = this.getDist();
            this.wdth = args.wdth ? ~~this.getAttr(dist, 5, 200) : 100;
            this.wght = args.wght ? ~~this.getAttr(dist, 100, 800) : 400;
            this.alpha = args.alpha ? this.getAttr(dist, 0, 1).toFixed(2) : 1;
            this.ital = args.ital ? this.getAttr(dist, 0, 1).toFixed(2) : 0;
            // Рисуем символ с обновленными стилями.
            this.draw();
        }
        // Применение стилей к элементу span.
        this.draw = function() {
            var style = "";
            style += "opacity: " + this.alpha + ";";
            style += "font-variation-settings: 'wght' " + this.wght + ", 'wdth' " + this.wdth + ", 'ital' " + this.ital + ";";
            span.style = style;
        }
        return this;
    }
    // Класс `VFont` управляет анимацией и отображением всех символов.
    var VFont = function() {
        this.flex = true;  // Переменная для управления макетом.
        this.alpha = false; // Переменная для управления прозрачностью символов.
        this.width = true;  // Переменная для управления шириной символов.
        this.weight = true; // Переменная для управления весом (жирностью) символов.
        var str, chars = [];
        // Инициализация символов и их анимации.
        this.init = function() {
            str = title.innerText;
            title.innerHTML = "";
            for (var i = 0; i < str.length; i++) {
                var _char = new Char(title, str[i]);
                chars.push(_char);
            }
            // Установка размера и стилей.
            this.set();
        }
        // Установка классов и начального состояния символов.
        this.set = function() {
            title.className = this.flex ? "flex" : "";
        }
        // Метод для анимации символов.
        this.animate = function() {
            // Перемещение мыши и вызов метода отрисовки.
            mouse.x += (cursor.x - mouse.x) / 20;
            mouse.y += (cursor.y - mouse.y) / 20;
            requestAnimationFrame(this.animate.bind(this));
            // Отрисовка символов с обновленными стилями.
            this.render();
        }
        // Отрисовка всех символов и их обновление.
        this.render = function() {
            maxDist = title.getBoundingClientRect().width / 2;
            for (var i = 0; i < chars.length; i++) {
            chars[i].update({
                wght: this.weight,
                wdth: this.width,
                alpha: this.alpha
            });
            }
        }
        // Инициализация и запуск анимации.
        this.init();
        this.animate();
        return this;
    }
    // Содание нового экземпляра класса VFont.
    new VFont();
    }
})