// import { neonCursor } from 'threejs-toys';
// // https://codepen.io/soju22/pen/wvyBorP
// export default (shaderPoints,curvePoints,curveLerp,radius1,radius2,velocityTreshold,sleepRadiusX,sleepRadiusY,sleepTimeCoefX,sleepTimeCoefY) => ({
//     async init() {
//         neonCursor({
//             el: this.$root,
//             shaderPoints,
//             curvePoints,
//             curveLerp,
//             radius1,
//             radius2,
//             velocityTreshold,
//             sleepRadiusX,
//             sleepRadiusY,
//             sleepTimeCoefX,
//             sleepTimeCoefY
//         })
//     }
// })
export default () => ({
    async init() {
        
    }
})