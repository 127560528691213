export default () => ({
    async init() {
        const { default: Swiper } = await import("../libs/Swiper");
        var swiper1 = new Swiper(this.$refs.swiper1, {
            spaceBetween: 10,
            slidesPerView: 3.3,
            direction: "vertical",
            watchSlidesProgress: true,
            breakpoints: {
                320: {
                    direction: "horizontal",
                    slidesPerView: 2.9,
                    spaceBetween: 4,
                },
                1024: {
                    direction: "vertical",
                    slidesPerView: 3.3,
                    spaceBetween: 10,
                }
            }
        });
        var swiper2 = new Swiper(this.$refs.swiper2, {
            spaceBetween: 10,
            grabCursor: true,
            navigation: {
                nextEl: this.$refs.next,
                prevEl: this.$refs.prev,
            },
            thumbs: {
                swiper: swiper1,
            },
        });
    }
})