export default (maxTakeMonth = 10) => ({ 
    takeProjectsMonthText: '',
    seatsLeft: '',
    monthLib: {
        '0': 'январе',
        '1': 'феврале',
        '2': 'марте',
        '3': 'апреле',
        '4': 'мае',
        '5': 'июне',
        '6': 'июле',
        '7': 'августе',
        '8': 'сентябре',
        '9': 'октябре',
        '10': 'ноябре',
        '11': 'декабре',
    },
    async init() {
        if (!Number.isInteger(maxTakeMonth) && maxTakeMonth < 0) 
            maxTakeMonth = 10;

        const currentDate = new Date();
        const currentMonthIndex = currentDate.getMonth();
        const currentDay = currentDate.getDate();

        // Вычисляем количество 3-дневных периодов, прошедших с 1-го числа месяца
        const periodsPassed = Math.max(1, Math.floor((currentDay - 1) / 3));

        // Уменьшаем maxTakeMonth на количество прошедших периодов
        const adjustedMaxTakeMonth = Math.max(1, maxTakeMonth - periodsPassed);

        this.takeProjectsMonthText = `Возьмём ${numDecline(maxTakeMonth, ['проект', 'проекта', 'проектов'])} в ${this.monthLib[currentMonthIndex]}`;
        this.seatsLeft = `Осталось ${numDecline(adjustedMaxTakeMonth, ['место', 'места', 'мест'])}!`;
    }
})