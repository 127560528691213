export default (speed = 300, loop = true) => ({
    bgc: '',
    department: '',
    async init() {
        const { default: Swiper } = await import("../libs/Swiper");
        const swiper = new Swiper(this.$refs.swiper, {
            spaceBetween: 30,
            init: false,
            loop,
            speed,
        });
        const updateActiveSlideInfo = () => {
            const activeSlide = swiper.slides[swiper.activeIndex];
            this.bgc = activeSlide.dataset.color;
            this.department = activeSlide.dataset.department;
        };
        swiper.on('init', updateActiveSlideInfo);
        swiper.init();
        swiper.on('activeIndexChange', updateActiveSlideInfo);
    },
});