import Alpine from "alpinejs";
import mask from '@alpinejs/mask';
import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
Alpine.plugin(mask);
Alpine.plugin(collapse);
Alpine.plugin(intersect);

import ContactsMap from "./ContactsMap";
Alpine.data("ContactsMap", ContactsMap);

import Accordion from "./Accordion";
Alpine.data("Accordion", Accordion);

import PhoneInputMask from "./PhoneInputMask";
Alpine.data("PhoneInputMask", PhoneInputMask);

import SimpleSlider from "./SimpleSlider";
Alpine.data("SimpleSlider", SimpleSlider);

import FancyboxGallery from "./FancyboxGallery";
Alpine.data("FancyboxGallery", FancyboxGallery);

import OpenModal from "./OpenModal"
Alpine.data("OpenModal", OpenModal);

import VariableFont from "./VariableFont"
Alpine.data("VariableFont", VariableFont);

import SectionScrollCheck from "./SectionScrollCheck"
Alpine.data("SectionScrollCheck", SectionScrollCheck);

import OnlyLetters from "./OnlyLetters"
Alpine.data('OnlyLetters', OnlyLetters);

import NeonCursor from "./NeonCursor"
Alpine.data('NeonCursor', NeonCursor);

import SliderTeam from "./SliderTeam"
Alpine.data('SliderTeam', SliderTeam);

import KP from "./KP"
Alpine.data('KP', KP);

import RewsShow from "./RewsShow"
Alpine.data('RewsShow', RewsShow);

import SettingTariff from "./SettingTariff"
Alpine.data('SettingTariff', SettingTariff);

import ContentSlider from "./ContentSlider"
Alpine.data('ContentSlider', ContentSlider);

import ScrollablePlace from "./ScrollablePlace"
Alpine.data('ScrollablePlace', ScrollablePlace);

import CheckList from "./CheckList"
Alpine.data('CheckList', CheckList);

import MainBanner from "./MainBanner"
Alpine.data('MainBanner', MainBanner);

Alpine.store("mainMenu", {
    open: false,
});

Alpine.store("blurAtHoverMenu", {
    blur: false,
});

Alpine.store("CityUser", {
    city: 'Вологда',
});

Alpine.start();
