export default () => ({
    phone: null,
    file_name: '',
    async init() {
        this.$refs.image.style.display = 'none';
    },
    get btn_submit_class() {
        let classes = {};
        if( this.phone == '' || !(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(this.phone)) )
            classes['disabled'] = true;
        return classes;
    },
    get_file_info(e) {
        const file = e.target.files[0];
        if( !file ) 
            return;
        if( !['application/msword','text/plain','application/pdf','image/jpeg'].includes(file.type) ) {
            e.target.parentElement.classList.add("_error");
            return;
        }
        if( file.size >= 30000000 ) { // 30Mb
            e.target.parentElement.classList.add("_error");
            return;
        }
        e.target.parentElement.classList.remove("_error");
        this.$refs.file_type.style.display = 'none';
        this.file_name = file.name;
    },
    get input_clicable() {
        if( this.file_name !== '' )
            return 'z-index: -1;';
        return '';
    },
    input_clear() {
        this.$refs.file_input.value = null;
        this.file_name = '';
        this.$refs.file_type.style.display = 'block';
    }
})
