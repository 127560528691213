export default (threshold = 0.5, rootMargin = '0px') => ({
    init() {
        console.log('init');
        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const scrollPosition = window.scrollY || document.documentElement.scrollTop;
                const elementPosition = entry.boundingClientRect.top + scrollPosition;
                if (entry.isIntersecting) {
                    this.$refs.element.classList.add('active');
                    console.log('active');
                } else if (this.$refs.element.classList.contains('active') && elementPosition > scrollPosition) {
                    this.$refs.element.classList.remove('active');
                    console.log('nonactive');
                }
            });
        }, {
            root: null,
            rootMargin,
            threshold,
        });
        this.observer.observe(this.$root);
    },
});