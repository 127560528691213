// export default () => ({
//     open: false,
//     tooTall: false,
//     height: '',
//     init() {
//         this.$nextTick(() => {
//             this.height = this.$refs.text.scrollHeight;
//             this.tooTall = this.height > 65;
//         })
//     }
// })